.calendar {
    
    .calendar-event {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 0.3125rem;

            @include screen-tablet {
                display: inline-block;
                .ant-badge {
                    margin-right: 5px;
                }
                .ant-badge-status-text {
                    display: none;
                }
            }
        }
    }

    .calendar-list {
        margin-bottom: 2rem;

        .calendar-list-item {
            padding: 5px 10px;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            margin-bottom: 15px;
            display: flex;
            margin-left: 20px;
            background-color: $gray-lightest;
            align-items: center;
            justify-content: space-between;
            @include screen-mobile-above {
                max-width: 80%;
            }
            .calendar-list-item-delete {
                opacity: 1;
                cursor: pointer;
                font-size: $font-size-md;
                @include transition(all 0.1s ease-in-out);

                &:hover {
                    color: $danger;
                }
            }
        }
    }
}
